import axios from 'axios'
import { toast } from 'sonner'
import links from '../links.json'
import { route } from './route'
import { addDays, addMonths, addYears, format } from 'date-fns'
import { isArray, isEqual, isObject, transform, find, pickBy } from 'lodash'
import { DateFormat } from '../constants'
import { Trans } from 'react-i18next'
import CryptoJS from 'crypto-js'

export const setDataNumberingSeries = async (dataField, value, setData, model, relationName = 'numbering_series') => {
  setData(dataField, value)

  let additionalData = {
    numbering_series_id: null,
    no: null,
    [relationName]: null
  }

  if (value) {
    try {
      const numberingSeries = await axios.get(route(links.administration.numberingSeries.getInfo, { numberingSeries: value }))

      let no = numberingSeries.data.number

      if (model) {
        if (model.numbering_series_id === numberingSeries.data.model.id) {
          no = model.no

          toast.info(<Trans i18nKey='messages.same_numbering_series_old_number_assigned' />)
        } else {
          toast.info(<Trans i18nKey='messages.different_numbering_series_new_number_assigned' />)
        }
      }

      additionalData = {
        numbering_series_id: value,
        no,
        [relationName]: numberingSeries.data.model
      }

      if (numberingSeries?.data?.message) {
        toast.info(numberingSeries?.data?.message)
      }
    } catch (error) {
      toast.error(error?.response?.data?.message ?? error?.message)
    }
  }

  setData((data) => ({
    ...data,
    ...additionalData
  }))
}

export const refreshRecordLock = async (modelType, id) => {
  try {
    await axios.post(route(links.application.recordLocks.refresh, {
      modelType,
      id
    }))

    const refreshLockInterval = setInterval(() => {
      axios.post(route(links.application.recordLocks.refresh, {
        modelType,
        id
      }))
    }, 60 * 1000)

    return () => {
      clearInterval(refreshLockInterval)
    }
  } catch (error) {
    toast.error(error?.response?.data?.message ?? error?.message)
  }
}

export const parseDMY = (string) => {
  const val = {
    D: 0,
    M: 0,
    Y: 0
  }

  const chars = (string.toUpperCase()).split('')
  let buildVal = ''

  chars.forEach(char => {
    if (!isNaN(char)) {
      buildVal += char
    } else {
      val[char] = parseInt(buildVal)
      buildVal = ''
    }
  })

  return val
}

export const addDMY = (date, string) => {
  const val = parseDMY(string)

  let buildDate = date
  if (!(buildDate instanceof Date)) {
    buildDate = new Date(buildDate)
  }

  buildDate = addDays(buildDate, val.D)
  buildDate = addMonths(buildDate, val.M)
  buildDate = addYears(buildDate, val.Y)

  return format(buildDate, DateFormat.serialization.date)
}

/**
 * Find difference between two objects
 * @param  {object} origObj - Source object to compare newObj against
 * @param  {object} newObj  - New object with potential changes
 * @return {object} differences
 */
// eslint-disable-next-line no-unused-vars
const differenceObjects = (origObj, newObj) => {
  function changes (newObj, origObj) {
    let arrayIndexCounter = 0
    return transform(newObj, function (result, value, key) {
      if (!isEqual(value, origObj[key])) {
        const resultKey = isArray(origObj) ? arrayIndexCounter++ : key
        result[resultKey] = (isObject(value) && isObject(origObj[key])) ? changes(value, origObj[key]) : value
      }
    })
  }

  return changes(newObj, origObj)
}

export const compareProps = (prevProps, nextProps, ignoreProps = []) => {
  const ignorePropValues = {}

  ignoreProps.forEach(ip => {
    ignorePropValues[ip] = undefined
  })

  const localPrevProps = { ...prevProps, ...ignorePropValues }
  const localNextProps = { ...nextProps, ...ignorePropValues }

  return isEqual(localPrevProps, localNextProps)
}

export const getSetting = (settings, searchSetting, defaultValue = null) => {
  const setting = find(settings, (object) => {
    return searchSetting === object.key
  })

  return setting ? setting.value : defaultValue
}

export const objectOnlyValues = (data, returnFormData = false) => {
  return pickBy(data, (value) => {
    return !!value
  })
}

export const getTrans = (obj, app) => {
  return obj[app.locale] ?? obj[app.fallback_locale]
}

export const parseValueToBoolean = (value) => {
  if (typeof (value) === 'string') {
    value = value.trim().toLowerCase()
  }

  switch (value) {
    case true:
    case 'true':
    case 1:
    case '1':
    case 'on':
    case 'yes':
      return true
    default:
      return false
  }
}

export const getCallableModel = (data, model) => {
  if (model instanceof Function) {
    return model(data)
  }

  return data?.[model]
}

export const jsonParseFallback = (str) => {
  try {
    return JSON.parse(str)
  } catch (e) {
    return str
  }
}

export const generateHMACSignature = async (payload, secretKey) => {
  const isCryptoAvailable = typeof crypto !== 'undefined' && crypto.subtle

  if (isCryptoAvailable) {
    // Use Web Crypto API (modern environments)
    const enc = new TextEncoder()
    const keyData = enc.encode(secretKey)
    const payloadData = enc.encode(payload)

    const key = await crypto.subtle.importKey(
      'raw',
      keyData,
      { name: 'HMAC', hash: { name: 'SHA-256' } },
      false,
      ['sign']
    )

    const signatureBuffer = await crypto.subtle.sign('HMAC', key, payloadData)

    // Convert ArrayBuffer to Hex String
    return Array.from(new Uint8Array(signatureBuffer))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')
  } else {
    // Fallback using CryptoJS (works in older browsers)
    return CryptoJS.HmacSHA256(payload, secretKey).toString(CryptoJS.enc.Hex)
  }
}
