export const PRODUCT_TYPE = {
  MATERIAL: 10,
  SERVICE: 20,
  BOM: 30
}

export const PRODUCT_TYPES_FOR_RETURN = [
  PRODUCT_TYPE.SERVICE
]

export const PRODUCT_TYPES_FOR_SALES = [
  PRODUCT_TYPE.MATERIAL,
  PRODUCT_TYPE.SERVICE,
  PRODUCT_TYPE.BOM
]

export const PRODUCT_TYPES_FOR_PURCHASE = [
  PRODUCT_TYPE.MATERIAL,
  PRODUCT_TYPE.SERVICE
]
