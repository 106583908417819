export const PRODUCT_FLOW_TYPE = {
  DONT_SHOW_IN_DOCUMENTS: 10,
  PURCHASE_SALES_DOCUMENTS: 20,
  PURCHASE_DOCUMENTS: 30,
  SALES_DOCUMENTS: 40
}

export const PRODUCT_FLOW_TYPES_FOR_SALES = [
  PRODUCT_FLOW_TYPE.PURCHASE_SALES_DOCUMENTS,
  PRODUCT_FLOW_TYPE.SALES_DOCUMENTS
]

export const PRODUCT_FLOW_TYPES_FOR_PURCHASE = [
  PRODUCT_FLOW_TYPE.PURCHASE_SALES_DOCUMENTS,
  PRODUCT_FLOW_TYPE.PURCHASE_DOCUMENTS
]
