import React, { useContext, useEffect, useState } from 'react'
import { Link } from '../../utils'
import { Bell, Menu, Sun, Moon, Minimize, Fullscreen, Search } from 'lucide-react'
import UserPanel from './UserPanel'
import Button from 'devextreme-react/button'
import { LogoLight } from '../Icons'
import { MenuCtx } from '../Menu'
import AddNewPanel from './AddNewPanel'
import { router, usePage } from '@inertiajs/react'
import { useTranslation } from 'react-i18next'
import { AppCtx } from '../providers/AppProvider'
import Globalize from 'globalize'
import mkMessages from '../../lang/mk.json'
import supplementalCldrData from 'devextreme-cldr-data/supplemental.json'
import mkCldrData from 'devextreme-cldr-data/mk.json'
import GlobalSearch from './GlobalSearch/GlobalSearch.jsx'

const Header = ({ menuToggleEnabled }) => {
  const { t, i18n } = useTranslation()
  const { toggleMenu } = useContext(MenuCtx)
  const { setLanguage, links } = useContext(AppCtx)
  const { auth, company, app } = usePage().props
  const [showGlobalSearch, setShowGlobalSearch] = useState(false)

  useEffect(() => {
    if (i18n.language !== app.locale) {
      i18n.changeLanguage(app.locale)
      setLanguage(app.locale)

      if (app.locale === 'mk') {
        Globalize.load(mkCldrData, supplementalCldrData)
        Globalize.loadMessages(mkMessages)
      }
      Globalize.locale(app.locale)
    }
  }, [app.locale])

  const storedDarkMode = () => {
    if (typeof window !== 'undefined') {
      return JSON.parse(window.localStorage.getItem('DARK_MODE')) ||
        (!('DARK_MODE' in window.localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
    }

    return false
  }

  const [darkMode, setDarkMode] = useState(storedDarkMode)
  const toggleDarkMode = () => setDarkMode(!darkMode)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('DARK_MODE', JSON.stringify(darkMode))
    }

    if (darkMode) {
      document.querySelector('html').classList.add('dark')
      document.querySelector('body').classList.add('dx-swatch-moho-cream-dark')

      return
    }

    document.querySelector('html').classList.remove('dark')
    document.querySelector('body').classList.remove('dx-swatch-moho-cream-dark')
  }, [darkMode])

  const [isFullscreen, setIsFullscreen] = useState(Boolean(document.fullscreenElement))
  const fullscreenEnabled = document?.fullscreenEnabled

  useEffect(() => {
    function onFullscreenChange () {
      setIsFullscreen(Boolean(document.fullscreenElement))
    }

    document.addEventListener('fullscreenchange', onFullscreenChange)

    return () => document.removeEventListener('fullscreenchange', onFullscreenChange)
  }, [])

  const toggleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
        .then(() => {
          setIsFullscreen(true)
        })
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
        .then(() => {
          setIsFullscreen(false)
        })
    }
  }

  return (
    <header
      className='main-header sticky top-0 z-40 w-full h-14 flex flex-col items-center bg-navyGreen text-white border-b-2 border-darkCyan shadow'
    >
      <div className='mx-auto flex w-full flex-1 items-center justify-between px-4 py-2'>
        <div className='flex items-center space-x-4'>
          <div className='flex items-center space-x-4'>
            {menuToggleEnabled &&
              <button
                type='button'
                aria-label='Menu button'
                onClick={toggleMenu}
              >
                <Menu fill='currentColor' />
              </button>}

            <Link href='/' className='flex-none'>

              <div className='flex items-center justify-center'>
                {company && company.logo &&
                  <img
                    src={company.logo.extra_small}
                    // style={{ height: '26px' }}
                    alt={company?.name}
                    className='mx-auto h-6 max-w-20 sm:max-w-none'
                  />}

                {!company.logo &&
                  <>
                    <span className='sr-only'>CReaM</span>
                    <LogoLight className='mx-auto h-6 max-w-20 sm:max-w-none' />
                  </>}
              </div>

            </Link>
          </div>
        </div>
        <nav className='flex items-center' />

        <div>
          <Button
            className='search-panel-button sm:mr-1'
            stylingMode='outlined'
            hint={t('ui.search')}
            elementAttr={{
              'aria-label': t('ui.search')
            }}
            onClick={() => setShowGlobalSearch(true)}
          >
            <Search size={18} className='text-white' />
          </Button>

          {fullscreenEnabled &&
            <Button
              className='fullscreen-panel-button sm:mr-1'
              stylingMode='outlined'
              onClick={toggleFullscreen}
              hint={t('labels.toggle_fullscreen')}
              elementAttr={{
                'aria-label': t('labels.toggle_fullscreen')
              }}
            >
              <Minimize size={20} className={`text-white ${isFullscreen ? 'inline' : 'hidden'}`} />
              <Fullscreen size={20} className={`text-white ${!isFullscreen ? 'inline' : 'hidden'}`} />
            </Button>}

          <Button
            className='add-new-panel-button sm:mr-1'
            stylingMode='outlined'
            hint={t('labels.quick_actions')}
            elementAttr={{
              'aria-label': t('labels.quick_actions')
            }}
          >
            <AddNewPanel menuMode='context' />
          </Button>

          <Button
            className='notifications-panel-button sm:mr-1'
            onClick={() => {
              router.visit(links.notifications.index)
            }}
            stylingMode='outlined'
            hint={t('labels.notifications')}
            elementAttr={{
              'aria-label': t('labels.notifications')
            }}
          >
            <i className='dx-icon dx-svg-icon'>
              <Bell size={20} className='text-white' />
            </i>
            {auth.hasUnreadNotifications &&
              <div
                className='absolute inline-flex w-3 h-3 border-2 border-navyGreen bg-red-500 rounded-full top-[3px] right-[6px]'
              />}
          </Button>

          <Button
            className='theme-panel-button sm:mr-1'
            stylingMode='outlined'
            hint={t('labels.theme')}
            onClick={toggleDarkMode}
          >
            <span className='sr-only'>
              <span className='dark:hidden'>Switch to dark theme</span>
              <span className='hidden dark:inline'>Switch to light theme</span>
            </span>

            <Sun size={20} className='text-white hidden dark:inline' />
            <Moon size={20} className='text-white dark:hidden' />
          </Button>

          <Button
            className='user-panel-button'
            width='auto'
            height='100%'
            stylingMode='text'
            hint={t('labels.user_menu')}
            elementAttr={{
              'aria-label': t('labels.user_menu')
            }}
          >
            <UserPanel menuMode='context' />
          </Button>
        </div>
      </div>

      <GlobalSearch
        showGlobalSearch={showGlobalSearch}
        setShowGlobalSearch={setShowGlobalSearch}
      />
    </header>
  )
}

export default Header
