import {
  MODEL_TYPE
} from './model_types'

export const DOCUMENT_LINE_TYPE = {
  PRODUCT: 10,
  RESOURCE: 20,
  FIXED_ASSET: 30,
  SMALL_INVENTORY: 40,
  CHARGE_PRODUCT: 50
}

export const DOCUMENT_LINE_TYPE_TO_MODEL_TYPE = {
  [DOCUMENT_LINE_TYPE.PRODUCT]: MODEL_TYPE.PRODUCT,
  [DOCUMENT_LINE_TYPE.RESOURCE]: MODEL_TYPE.RESOURCE,
  [DOCUMENT_LINE_TYPE.FIXED_ASSET]: MODEL_TYPE.FIXED_ASSET,
  [DOCUMENT_LINE_TYPE.SMALL_INVENTORY]: MODEL_TYPE.SMALL_INVENTORY,
  [DOCUMENT_LINE_TYPE.CHARGE_PRODUCT]: MODEL_TYPE.PRODUCT
}

export const DOCUMENT_LINE_TYPES_FOR_RETURN = [
  DOCUMENT_LINE_TYPE.PRODUCT,
  DOCUMENT_LINE_TYPE.CHARGE_PRODUCT
]

export const DOCUMENT_LINE_TYPES_FOR_SALES = [
  DOCUMENT_LINE_TYPE.PRODUCT,
  DOCUMENT_LINE_TYPE.RESOURCE,
  DOCUMENT_LINE_TYPE.FIXED_ASSET,
  DOCUMENT_LINE_TYPE.CHARGE_PRODUCT
]

export const DOCUMENT_LINE_TYPES_FOR_PURCHASE = [
  DOCUMENT_LINE_TYPE.PRODUCT,
  DOCUMENT_LINE_TYPE.FIXED_ASSET,
  DOCUMENT_LINE_TYPE.SMALL_INVENTORY,
  DOCUMENT_LINE_TYPE.CHARGE_PRODUCT
]
