import React, { useContext, useMemo } from 'react'
import ContextMenu, { Position } from 'devextreme-react/context-menu'
import List from 'devextreme-react/list'
import { usePage, router } from '@inertiajs/react'
import { Trans } from 'react-i18next'
import { Plus } from 'lucide-react'
import { renderToStaticMarkup } from 'react-dom/server'
import { can, route } from '../../utils'
import { AppCtx } from '../providers/AppProvider'
import ScrollView from 'devextreme/ui/scroll_view'

export default function AddNewPanel ({ menuMode }) {
  const { auth, app } = usePage().props
  const { links } = useContext(AppCtx)

  const menuItems = useMemo(() => ([
    {
      text: <Trans i18nKey='labels.product' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_products'),
      url: route(links.products.create, { 'product?': null })
    },
    {
      text: <Trans i18nKey='labels.account' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_accounts'),
      url: links.accounts.create
    },
    {
      text: <Trans i18nKey='labels.numbering_series' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_numbering_series'),
      url: links.administration.numberingSeries.create
    },
    {
      text: <Trans i18nKey='labels.purchase_quote' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_purchase_quote'),
      url: route(links.documents.create, { documentTypeBind: 'purchase-quote' })
    },
    {
      text: <Trans i18nKey='labels.purchase_contract' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_purchase_contract'),
      url: route(links.documents.create, { documentTypeBind: 'purchase-contract' })
    },
    {
      text: <Trans i18nKey='labels.purchase_invoice' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_purchase_invoice'),
      url: route(links.documents.create, { documentTypeBind: 'purchase-invoice' })
    },
    {
      text: <Trans i18nKey='labels.purchase_order' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_purchase_order'),
      url: route(links.documents.create, { documentTypeBind: 'purchase-order' })
    },
    {
      text: <Trans i18nKey='labels.purchase_receipt' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_purchase_receipt'),
      url: route(links.documents.create, { documentTypeBind: 'purchase-receipt' })
    },
    {
      text: <Trans i18nKey='labels.sales_opportunity' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_sales_opportunity'),
      url: route(links.documents.create, { documentTypeBind: 'sales-opportunity' })
    },
    {
      text: <Trans i18nKey='labels.sales_quote' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_sales_quote'),
      url: route(links.documents.create, { documentTypeBind: 'sales-quote' })
    },
    {
      text: <Trans i18nKey='labels.sales_contract' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_sales_contract'),
      url: route(links.documents.create, { documentTypeBind: 'sales-contract' })
    },
    {
      text: <Trans i18nKey='labels.sales_invoice' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_sales_invoice'),
      url: route(links.documents.create, { documentTypeBind: 'sales-invoice' })
    },
    {
      text: <Trans i18nKey='labels.sales_order' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_sales_order'),
      url: route(links.documents.create, { documentTypeBind: 'sales-order' })
    },
    {
      text: <Trans i18nKey='labels.sales_shipment' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_sales_shipment'),
      url: route(links.documents.create, { documentTypeBind: 'sales-shipment' })
    },
    {
      text: <Trans i18nKey='labels.sales_retail_transaction' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_sales_retail_transaction'),
      url: route(links.documents.create, { documentTypeBind: 'sales-retail-transaction' })
    },
    {
      text: <Trans i18nKey='labels.sales_retail_refund_transaction' />,
      icon: renderToStaticMarkup(<Plus size={14} />),
      show: can(auth, 'create_sales_retail_refund_transaction'),
      url: route(links.documents.create, { documentTypeBind: 'sales-retail-refund-transaction' })
    }
  ]), [auth, app.locale])

  // const items = menuItems.filter(i => i.show)
  const items = useMemo(() => (
    menuItems.filter(i => i.show)
  ),
  [auth, app.locale])

  return (
    <div className='add-new-panel absolute w-full h-full flex items-center justify-center'>
      <Plus size={20} className='text-white' />

      {menuMode === 'context' && (
        <ContextMenu
          items={items}
          target='.add-new-panel-button'
          showEvent='dxclick'
          width={200}
          cssClass='quick-add-new-menu custom-menu-item-link'
          itemRender={(item) => {
            if (item.show) {
              return (
                <a
                  className='!text-inherit dx-item-content dx-menu-item-content'
                  href={item.url}
                  onClick={e => {
                    e.preventDefault()

                    router.visit(item.url)
                  }}
                >
                  {!!item.icon &&
                    <i className='dx-icon dx-svg-icon' dangerouslySetInnerHTML={{ __html: item.icon }} />}

                  <span className='dx-menu-item-text'>{item.text}</span>
                </a>
              )
            }

            return null
          }}
          onItemRendered={(e) => {
            const el = e.itemElement.closest('.dx-submenu')

            if (ScrollView.getInstance(el) === undefined) {
              el.style.height = '300px'
              // eslint-disable-next-line no-new
              new ScrollView(el, { })
            }
          }}
        >
          <Position my={{ x: 'center', y: 'top' }} at={{ x: 'center', y: 'bottom' }} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className='dx-toolbar-menu-action' items={items} />
      )}
    </div>
  )
}
