import { DOCUMENT_LINE_TYPES_FOR_PURCHASE, DOCUMENT_LINE_TYPES_FOR_SALES } from './document_line_types'
import { PRODUCT_TYPES_FOR_PURCHASE, PRODUCT_TYPES_FOR_SALES } from './product_types.jsx'
import { PRODUCT_FLOW_TYPES_FOR_PURCHASE, PRODUCT_FLOW_TYPES_FOR_SALES } from './product_flow_types.jsx'
import { ACCOUNT_SELECT_TYPES } from './account_select_types.jsx'

export const DOCUMENT_MAIN_TYPE = {
  PURCHASE: 10,
  SALES: 20,
  SALES_RETAIL: 30,
  SERVICE: 40
}

export const DOCUMENT_MAIN_TYPE_MAPPER = {
  LINE_TYPES: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: DOCUMENT_LINE_TYPES_FOR_PURCHASE,
    [DOCUMENT_MAIN_TYPE.SALES]: DOCUMENT_LINE_TYPES_FOR_SALES,
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: DOCUMENT_LINE_TYPES_FOR_SALES,
    [DOCUMENT_MAIN_TYPE.SERVICE]: DOCUMENT_LINE_TYPES_FOR_SALES
  },
  PRODUCT_TYPES: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: PRODUCT_TYPES_FOR_PURCHASE,
    [DOCUMENT_MAIN_TYPE.SALES]: PRODUCT_TYPES_FOR_SALES,
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: PRODUCT_TYPES_FOR_SALES,
    [DOCUMENT_MAIN_TYPE.SERVICE]: PRODUCT_TYPES_FOR_SALES
  },
  PRODUCT_FLOW_TYPES: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: PRODUCT_FLOW_TYPES_FOR_PURCHASE,
    [DOCUMENT_MAIN_TYPE.SALES]: PRODUCT_FLOW_TYPES_FOR_SALES,
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: PRODUCT_FLOW_TYPES_FOR_SALES,
    [DOCUMENT_MAIN_TYPE.SERVICE]: PRODUCT_FLOW_TYPES_FOR_SALES
  },
  PRICES_INCLUDE_VAT: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: false,
    [DOCUMENT_MAIN_TYPE.SALES]: false,
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: true,
    [DOCUMENT_MAIN_TYPE.SERVICE]: false
  },
  PRODUCT_UOM_RELATION: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: 'purchase_uom',
    [DOCUMENT_MAIN_TYPE.SALES]: 'sales_uom',
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: 'sales_uom',
    [DOCUMENT_MAIN_TYPE.SERVICE]: 'sales_uom'
  },
  PRODUCT_VAT_RELATION: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: 'vat_product_group',
    [DOCUMENT_MAIN_TYPE.SALES]: 'sales_vat_product_group',
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: 'sales_vat_product_group',
    [DOCUMENT_MAIN_TYPE.SERVICE]: 'sales_vat_product_group'
  },
  ACCOUNT_VAT_RELATION: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: 'vat_vendor_posting_group',
    [DOCUMENT_MAIN_TYPE.SALES]: 'vat_customer_posting_group',
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: 'vat_customer_posting_group',
    [DOCUMENT_MAIN_TYPE.SERVICE]: 'vat_customer_posting_group'
  },
  ACCOUNT_CURRENCY_RELATION: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: 'vendor_currency',
    [DOCUMENT_MAIN_TYPE.SALES]: 'customer_currency',
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: 'customer_currency',
    [DOCUMENT_MAIN_TYPE.SERVICE]: 'customer_currency'
  },
  ACCOUNT_TYPE_RELATION: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: ACCOUNT_SELECT_TYPES.IS_VENDOR,
    [DOCUMENT_MAIN_TYPE.SALES]: ACCOUNT_SELECT_TYPES.IS_CUSTOMER,
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: ACCOUNT_SELECT_TYPES.IS_CUSTOMER,
    [DOCUMENT_MAIN_TYPE.SERVICE]: ACCOUNT_SELECT_TYPES.IS_CUSTOMER
  },
  PREFIX: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: 'purchase',
    [DOCUMENT_MAIN_TYPE.SALES]: 'sales',
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: 'sales',
    [DOCUMENT_MAIN_TYPE.SERVICE]: 'sales'
  },
  ACCOUNT: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: 'vendor',
    [DOCUMENT_MAIN_TYPE.SALES]: 'customer',
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: 'customer',
    [DOCUMENT_MAIN_TYPE.SERVICE]: 'customer'
  },
  SHIPMENT: {
    [DOCUMENT_MAIN_TYPE.PURCHASE]: 'receipt',
    [DOCUMENT_MAIN_TYPE.SALES]: 'shipment',
    [DOCUMENT_MAIN_TYPE.SALES_RETAIL]: 'shipment',
    [DOCUMENT_MAIN_TYPE.SERVICE]: 'shipment'
  }
}
