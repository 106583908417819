import enumConstants from './../enumConstants.json'
import { MODEL_TYPE } from './model_types.jsx'

export const JOURNAL_LINES_ENTITY_TYPE_TO_MODEL_TYPE = {
  [enumConstants.journal_line_entity_types.ACCOUNT]: MODEL_TYPE.ACCOUNT,
  [enumConstants.journal_line_entity_types.CUSTOMER]: MODEL_TYPE.ACCOUNT,
  [enumConstants.journal_line_entity_types.VENDOR]: MODEL_TYPE.ACCOUNT,
  [enumConstants.journal_line_entity_types.COMPANY]: MODEL_TYPE.COMPANY,
  [enumConstants.journal_line_entity_types.BANK_ACCOUNT]: MODEL_TYPE.BANK_ACCOUNT,
  [enumConstants.journal_line_entity_types.EMPLOYEE]: MODEL_TYPE.EMPLOYEE,
  [enumConstants.journal_line_entity_types.CASH_ACCOUNT]: MODEL_TYPE.CASH_ACCOUNT,
  [enumConstants.journal_line_entity_types.CHART_OF_ACCOUNT]: MODEL_TYPE.CHART_OF_ACCOUNT
}

export const JOURNAL_LINE_ENTITY_TYPE_MAPPER = {
  ENTITY_HEADER_TYPES: {
    [enumConstants.journal_line_entity_types.ACCOUNT]: [],
    [enumConstants.journal_line_entity_types.CUSTOMER]: [
      enumConstants.journal_line_entry_header_types.SALES_INVOICE
    ],
    [enumConstants.journal_line_entity_types.VENDOR]: [
      enumConstants.journal_line_entry_header_types.PURCHASE_INVOICE
    ],
    [enumConstants.journal_line_entity_types.COMPANY]: [
      enumConstants.journal_line_entry_header_types.BANK_STATEMENT
    ],
    [enumConstants.journal_line_entity_types.BANK_ACCOUNT]: [],
    [enumConstants.journal_line_entity_types.EMPLOYEE]: [],
    [enumConstants.journal_line_entity_types.CASH_ACCOUNT]: [],
    [enumConstants.journal_line_entity_types.CHART_OF_ACCOUNT]: []
  }
}
