import { MODEL_TYPE } from './model_types'
import enumConstants from './../enumConstants.json'

export const BANK_STATEMENT_ENTITY_TYPE_TO_MODEL_TYPE = {
  [enumConstants.bank_statement_line_entity_types.ACCOUNT]: MODEL_TYPE.ACCOUNT,
  [enumConstants.bank_statement_line_entity_types.CUSTOMER]: MODEL_TYPE.ACCOUNT,
  [enumConstants.bank_statement_line_entity_types.VENDOR]: MODEL_TYPE.ACCOUNT,
  [enumConstants.bank_statement_line_entity_types.COMPANY]: MODEL_TYPE.COMPANY,
  [enumConstants.bank_statement_line_entity_types.BANK_ACCOUNT]: MODEL_TYPE.BANK_ACCOUNT,
  [enumConstants.bank_statement_line_entity_types.EMPLOYEE]: MODEL_TYPE.EMPLOYEE,
  [enumConstants.bank_statement_line_entity_types.CASH_ACCOUNT]: MODEL_TYPE.CASH_ACCOUNT
}

export const BANK_STATEMENT_LINE_ENTITY_TYPE_MAPPER = {
  SOURCE_TYPES: {
    [enumConstants.bank_statement_line_entity_types.ACCOUNT]: [],
    [enumConstants.bank_statement_line_entity_types.CUSTOMER]: [
      enumConstants.bank_statement_line_source_types.SALES_INVOICE
    ],
    [enumConstants.bank_statement_line_entity_types.VENDOR]: [
      enumConstants.bank_statement_line_source_types.PURCHASE_INVOICE
    ],
    [enumConstants.bank_statement_line_entity_types.COMPANY]: [
      enumConstants.bank_statement_line_source_types.BANK_STATEMENT
    ],
    [enumConstants.bank_statement_line_entity_types.BANK_ACCOUNT]: [],
    [enumConstants.bank_statement_line_entity_types.EMPLOYEE]: [],
    [enumConstants.bank_statement_line_entity_types.CASH_ACCOUNT]: [],
    [enumConstants.bank_statement_line_entity_types.CHART_OF_ACCOUNT]: []
  }
}
