import { DOCUMENT_MAIN_TYPE } from '../constants/index.jsx'

export const BANK_STATEMENT_HELPER = {
  DOCUMENT: {
    bankStatementSourceApplyConfig: (config, entityModelId) => {
      config.params.filter.push(['account_id', '=', entityModelId])
    },
    bankStatementSourceApplyData: (data, model) => {
      data.debit = model.main_type === DOCUMENT_MAIN_TYPE.PURCHASE ? 0 : model.total
      data.credit = model.main_type === DOCUMENT_MAIN_TYPE.PURCHASE ? model.external_document_amount : 0

      data.source_model_date = model.date
      data.source_model_due_date = model.due_date
      data.source_model_ext_source_no = model.external_document_number
      data.source_model_ext_source_date = model.external_document_date

      data.amount = model.total

      data.location_id = model.location_id
      data.location = model.location
      data.responsibility_center_id = model.responsibility_center_id
      data.responsibility_center = model.responsibility_center
    }
  },
  BANK_STATEMENT: {
    bankStatementSourceApplyConfig: (config, entityModelId) => {
      //
    },
    bankStatementSourceApplyData: (data, model) => {
      // TODO: credit/debit values
      // data.debit = 0
      // data.credit = 0
      // data.debit_lcy = 0
      // data.credit_lcy = 0

      data.source_model_date = model.date

      data.amount = model.total
    }
  }
}
