import { DOCUMENT_MAIN_TYPE } from '../constants/index.jsx'

export const JOURNAL_HELPER = {
  DOCUMENT: {
    journalEntryHeaderApplyConfig: (config, entityModelId) => {
      config.params.filter.push(['account_id', '=', entityModelId])
    },
    journalEntryHeaderApplyData: (data, model) => {
      data.currency_id = model.currency_id
      data.currency = model.currency
      data.currency_rate = model.currency_rate

      data.debit = model.main_type === DOCUMENT_MAIN_TYPE.PURCHASE ? 0 : model.total
      data.credit = model.main_type === DOCUMENT_MAIN_TYPE.PURCHASE ? model.external_document_amount : 0
      data.debit_lcy = model.main_type === DOCUMENT_MAIN_TYPE.PURCHASE ? 0 : (model.total * model.currency_rate)
      data.credit_lcy = model.main_type === DOCUMENT_MAIN_TYPE.PURCHASE ? (model.external_document_amount * model.currency_rate) : 0

      data.entry_no = model.no
      data.entry_date = model.date
      data.entry_due_date = model.due_date
      data.ext_entry_no = model.external_document_number
      data.ext_entry_date = model.external_document_date

      data.amount = model.total

      data.location_id = model.location_id
      data.location = model.location
      data.responsibility_center_id = model.responsibility_center_id
      data.responsibility_center = model.responsibility_center

      data.bank_account_id = model.bank_account_id
      data.bank_account = model.bank_account
    },
    journalEntryLineApplyConfig: (config, entryHeaderModelId) => {
      //
    },
    journalEntryLineApplyData: (data, model) => {
      //
    }
  },
  BANK_STATEMENT: {
    journalEntryHeaderApplyConfig: (config, entityModelId) => {
      //
    },
    journalEntryHeaderApplyData: (data, model) => {
      data.currency_id = model.currency_id
      data.currency = model.currency
      data.currency_rate = model.currency_rate

      // TODO: credit/debit values
      // data.debit = 0
      // data.credit = 0
      // data.debit_lcy = 0
      // data.credit_lcy = 0

      data.entry_no = model.no
      data.entry_date = model.date

      data.amount = model.total

      data.bank_account_id = model.bank_account_id
      data.bank_account = model.bank_account
    },
    journalEntryLineApplyConfig: (config, entryHeaderModelId) => {
      //
    },
    journalEntryLineApplyData: (data, model) => {
      //
    }
  }
}
